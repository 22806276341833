@import "modules/responsive-type.scss";
@import url('https://fonts.googleapis.com/css?family=Changa+One|Puritan');
@import url('https://fonts.googleapis.com/css?family=Fugaz+One|Sunflower:300');

@font-face {
    font-family: 'Linearicons-Free';
    src:url('../fonts/Linearicons-Free.eot?w118d');
    src:url('../fonts/Linearicons-Free.eot?#iefixw118d') format('embedded-opentype'),
        url('../fonts/Linearicons-Free.woff2?w118d') format('woff2'),
        url('../fonts/Linearicons-Free.woff?w118d') format('woff'),
        url('../fonts/Linearicons-Free.ttf?w118d') format('truetype'),
        url('../fonts/Linearicons-Free.svg?w118d#Linearicons-Free') format('svg');
    font-weight: normal;
    font-style: normal;
}

.lnr {
    font-family: 'Linearicons-Free';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.lnr-home:before {
    content: "\e800";
}
.lnr-apartment:before {
    content: "\e801";
}
.lnr-pencil:before {
    content: "\e802";
}
.lnr-magic-wand:before {
    content: "\e803";
}
.lnr-drop:before {
    content: "\e804";
}
.lnr-lighter:before {
    content: "\e805";
}
.lnr-poop:before {
    content: "\e806";
}
.lnr-sun:before {
    content: "\e807";
}
.lnr-moon:before {
    content: "\e808";
}
.lnr-cloud:before {
    content: "\e809";
}
.lnr-cloud-upload:before {
    content: "\e80a";
}
.lnr-cloud-download:before {
    content: "\e80b";
}
.lnr-cloud-sync:before {
    content: "\e80c";
}
.lnr-cloud-check:before {
    content: "\e80d";
}
.lnr-database:before {
    content: "\e80e";
}
.lnr-lock:before {
    content: "\e80f";
}
.lnr-cog:before {
    content: "\e810";
}
.lnr-trash:before {
    content: "\e811";
}
.lnr-dice:before {
    content: "\e812";
}
.lnr-heart:before {
    content: "\e813";
}
.lnr-star:before {
    content: "\e814";
}
.lnr-star-half:before {
    content: "\e815";
}
.lnr-star-empty:before {
    content: "\e816";
}
.lnr-flag:before {
    content: "\e817";
}
.lnr-envelope:before {
    content: "\e818";
}
.lnr-paperclip:before {
    content: "\e819";
}
.lnr-inbox:before {
    content: "\e81a";
}
.lnr-eye:before {
    content: "\e81b";
}
.lnr-printer:before {
    content: "\e81c";
}
.lnr-file-empty:before {
    content: "\e81d";
}
.lnr-file-add:before {
    content: "\e81e";
}
.lnr-enter:before {
    content: "\e81f";
}
.lnr-exit:before {
    content: "\e820";
}
.lnr-graduation-hat:before {
    content: "\e821";
}
.lnr-license:before {
    content: "\e822";
}
.lnr-music-note:before {
    content: "\e823";
}
.lnr-film-play:before {
    content: "\e824";
}
.lnr-camera-video:before {
    content: "\e825";
}
.lnr-camera:before {
    content: "\e826";
}
.lnr-picture:before {
    content: "\e827";
}
.lnr-book:before {
    content: "\e828";
}
.lnr-bookmark:before {
    content: "\e829";
}
.lnr-user:before {
    content: "\e82a";
}
.lnr-users:before {
    content: "\e82b";
}
.lnr-shirt:before {
    content: "\e82c";
}
.lnr-store:before {
    content: "\e82d";
}
.lnr-cart:before {
    content: "\e82e";
}
.lnr-tag:before {
    content: "\e82f";
}
.lnr-phone-handset:before {
    content: "\e830";
}
.lnr-phone:before {
    content: "\e831";
}
.lnr-pushpin:before {
    content: "\e832";
}
.lnr-map-marker:before {
    content: "\e833";
}
.lnr-map:before {
    content: "\e834";
}
.lnr-location:before {
    content: "\e835";
}
.lnr-calendar-full:before {
    content: "\e836";
}
.lnr-keyboard:before {
    content: "\e837";
}
.lnr-spell-check:before {
    content: "\e838";
}
.lnr-screen:before {
    content: "\e839";
}
.lnr-smartphone:before {
    content: "\e83a";
}
.lnr-tablet:before {
    content: "\e83b";
}
.lnr-laptop:before {
    content: "\e83c";
}
.lnr-laptop-phone:before {
    content: "\e83d";
}
.lnr-power-switch:before {
    content: "\e83e";
}
.lnr-bubble:before {
    content: "\e83f";
}
.lnr-heart-pulse:before {
    content: "\e840";
}
.lnr-construction:before {
    content: "\e841";
}
.lnr-pie-chart:before {
    content: "\e842";
}
.lnr-chart-bars:before {
    content: "\e843";
}
.lnr-gift:before {
    content: "\e844";
}
.lnr-diamond:before {
    content: "\e845";
}
.lnr-linearicons:before {
    content: "\e846";
}
.lnr-dinner:before {
    content: "\e847";
}
.lnr-coffee-cup:before {
    content: "\e848";
}
.lnr-leaf:before {
    content: "\e849";
}
.lnr-paw:before {
    content: "\e84a";
}
.lnr-rocket:before {
    content: "\e84b";
}
.lnr-briefcase:before {
    content: "\e84c";
}
.lnr-bus:before {
    content: "\e84d";
}
.lnr-car:before {
    content: "\e84e";
}
.lnr-train:before {
    content: "\e84f";
}
.lnr-bicycle:before {
    content: "\e850";
}
.lnr-wheelchair:before {
    content: "\e851";
}
.lnr-select:before {
    content: "\e852";
}
.lnr-earth:before {
    content: "\e853";
}
.lnr-smile:before {
    content: "\e854";
}
.lnr-sad:before {
    content: "\e855";
}
.lnr-neutral:before {
    content: "\e856";
}
.lnr-mustache:before {
    content: "\e857";
}
.lnr-alarm:before {
    content: "\e858";
}
.lnr-bullhorn:before {
    content: "\e859";
}
.lnr-volume-high:before {
    content: "\e85a";
}
.lnr-volume-medium:before {
    content: "\e85b";
}
.lnr-volume-low:before {
    content: "\e85c";
}
.lnr-volume:before {
    content: "\e85d";
}
.lnr-mic:before {
    content: "\e85e";
}
.lnr-hourglass:before {
    content: "\e85f";
}
.lnr-undo:before {
    content: "\e860";
}
.lnr-redo:before {
    content: "\e861";
}
.lnr-sync:before {
    content: "\e862";
}
.lnr-history:before {
    content: "\e863";
}
.lnr-clock:before {
    content: "\e864";
}
.lnr-download:before {
    content: "\e865";
}
.lnr-upload:before {
    content: "\e866";
}
.lnr-enter-down:before {
    content: "\e867";
}
.lnr-exit-up:before {
    content: "\e868";
}
.lnr-bug:before {
    content: "\e869";
}
.lnr-code:before {
    content: "\e86a";
}
.lnr-link:before {
    content: "\e86b";
}
.lnr-unlink:before {
    content: "\e86c";
}
.lnr-thumbs-up:before {
    content: "\e86d";
}
.lnr-thumbs-down:before {
    content: "\e86e";
}
.lnr-magnifier:before {
    content: "\e86f";
}
.lnr-cross:before {
    content: "\e870";
}
.lnr-menu:before {
    content: "\e871";
}
.lnr-list:before {
    content: "\e872";
}
.lnr-chevron-up:before {
    content: "\e873";
}
.lnr-chevron-down:before {
    content: "\e874";
}
.lnr-chevron-left:before {
    content: "\e875";
}
.lnr-chevron-right:before {
    content: "\e876";
}
.lnr-arrow-up:before {
    content: "\e877";
}
.lnr-arrow-down:before {
    content: "\e878";
}
.lnr-arrow-left:before {
    content: "\e879";
}
.lnr-arrow-right:before {
    content: "\e87a";
}
.lnr-move:before {
    content: "\e87b";
}
.lnr-warning:before {
    content: "\e87c";
}
.lnr-question-circle:before {
    content: "\e87d";
}
.lnr-menu-circle:before {
    content: "\e87e";
}
.lnr-checkmark-circle:before {
    content: "\e87f";
}
.lnr-cross-circle:before {
    content: "\e880";
}
.lnr-plus-circle:before {
    content: "\e881";
}
.lnr-circle-minus:before {
    content: "\e882";
}
.lnr-arrow-up-circle:before {
    content: "\e883";
}
.lnr-arrow-down-circle:before {
    content: "\e884";
}
.lnr-arrow-left-circle:before {
    content: "\e885";
}
.lnr-arrow-right-circle:before {
    content: "\e886";
}
.lnr-chevron-up-circle:before {
    content: "\e887";
}
.lnr-chevron-down-circle:before {
    content: "\e888";
}
.lnr-chevron-left-circle:before {
    content: "\e889";
}
.lnr-chevron-right-circle:before {
    content: "\e88a";
}
.lnr-crop:before {
    content: "\e88b";
}
.lnr-frame-expand:before {
    content: "\e88c";
}
.lnr-frame-contract:before {
    content: "\e88d";
}
.lnr-layers:before {
    content: "\e88e";
}
.lnr-funnel:before {
    content: "\e88f";
}
.lnr-text-format:before {
    content: "\e890";
}
.lnr-text-format-remove:before {
    content: "\e891";
}
.lnr-text-size:before {
    content: "\e892";
}
.lnr-bold:before {
    content: "\e893";
}
.lnr-italic:before {
    content: "\e894";
}
.lnr-underline:before {
    content: "\e895";
}
.lnr-strikethrough:before {
    content: "\e896";
}
.lnr-highlight:before {
    content: "\e897";
}
.lnr-text-align-left:before {
    content: "\e898";
}
.lnr-text-align-center:before {
    content: "\e899";
}
.lnr-text-align-right:before {
    content: "\e89a";
}
.lnr-text-align-justify:before {
    content: "\e89b";
}
.lnr-line-spacing:before {
    content: "\e89c";
}
.lnr-indent-increase:before {
    content: "\e89d";
}
.lnr-indent-decrease:before {
    content: "\e89e";
}
.lnr-pilcrow:before {
    content: "\e89f";
}
.lnr-direction-ltr:before {
    content: "\e8a0";
}
.lnr-direction-rtl:before {
    content: "\e8a1";
}
.lnr-page-break:before {
    content: "\e8a2";
}
.lnr-sort-alpha-asc:before {
    content: "\e8a3";
}
.lnr-sort-amount-asc:before {
    content: "\e8a4";
}
.lnr-hand:before {
    content: "\e8a5";
}
.lnr-pointer-up:before {
    content: "\e8a6";
}
.lnr-pointer-right:before {
    content: "\e8a7";
}
.lnr-pointer-down:before {
    content: "\e8a8";
}
.lnr-pointer-left:before {
    content: "\e8a9";
}



$primary: #112F41;/* MAIN COLOR */
$secondary: #ED553B; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;

// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;

// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 

$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop

html, body {
    height: 100%;
    font-family: 'Sunflower', sans-serif;
}

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

p {
    font-size: 1rem;
}

h1,h2,h3,h4,h5,h6 {
    font-family: 'Fugaz One', cursive;
}

h1 {  
  font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h2 {  
  font-size: $mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h3 { 
  font-size: $mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
} 

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill, 
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;

    .navbar-nav {
        >li>a {
            text-align: center;
            margin-top: 22px;
            display: flex;
            align-items: center;
            color: $blk;
            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
            }
            &:focus,
            &:active {
                background: transparent;
                color: $blk;
                outline: 0;
            }
            &:hover {
                background: $primary;
                color: $wht;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin-top: 17px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 2em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        h2 {
            text-align: center;
        }
    }
    input {
        border: 1px solid #777;
        text-align: center;
    }
    input#username,
    input#password {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn;
        display: block;
        width: 75%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/

footer {
    padding: 50px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 250px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-width: 175px;
    }
    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {
    .logo {
        max-width: 125px;
    }
    .navbar-toggle {
        margin-top: 8px;
    }
}

.top-pad {
    h1,h2,h3,h4,h5,h6 {
          @include fluid-type($min_width, $max_width, 1.2 * $min_font, 1.4 * $max_font);

    }
    padding: 1rem;
    background: white;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media  (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */ 
    }
}

.terms,
.privacy {
        font-size: 1rem;
    
    & ol {
        li {
            font-size: 1rem;
        }
        & ol li {
            font-size: 0.85rem;
        }
    }
}

.banner {
    background-image: linear-gradient(
      rgba($primary, 0.5),
      rgba($secondary, 0.5)
    ),url(../img/banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 16rem 0;
    background-position: 50% 50%;
    color: white;
    @media (max-width: 991px) {
        padding: 12rem 0;
    }
    @media (max-width: 767px) {
        padding: 8rem 0;
    }
}

.couple {
    box-shadow: 0px 0px 20px rgba(0,0,0,0.25);
    border-radius: 3px;
}

[class*="couple"] {
    @media (max-width: 767px) {
        margin-bottom: 1rem;
    }
}

.intro-section {
    margin-top: 3rem;
    margin-bottom: 1rem;
}
.title {
    margin: 2rem 0;
}
.reasons-title {
    font-size: 1.2rem;
}
.lh {
    line-height: 2;
}
.btn {
    @include btn;
    margin: 0.5rem 0;
}
.bg-white {
    background: white;
}

body {
    background: url(../img/bg.jpg);
}

.intro-section, .mid-section {
    h1,h2,h3,h4,h5,h6 {
        color: $secondary;
    }
}
.intro-section, .mid-section, .bottom-section {
    border-radius: 15px;
     box-shadow: 0px 0px 20px rgba(0,0,0,0.25);
}


.icon-box span {
    font-size: 3rem;
    color: white;
    display: inline-block;
    margin: 1rem;
}

.icon-box {
    background: $secondary;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.25);
    overflow: auto;
    h3 {
        color: white;
        margin: 0 0 1rem 0;
    }
}
.mid-section {
    margin-top: 1rem;
}
.bottom-section {
    margin-top: 1rem;
    padding: 2rem;
    color: $secondary;
    margin-bottom: 1rem;
    h3 {
        @media (max-width: 767px) {
            font-size: 1.3rem;
        }
    }
}
@media (max-width: 650px) {
    .col-xs-4 {
        width: 100%;
    }
    [class*="couple"] img {
        max-width: 300px;
        display: block;
        margin: 0 auto;
        width: 100%;
    }
}